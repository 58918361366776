import { h } from "preact";
import useAuth from "js/components/User/useAuth.js";
import { useState } from "preact/hooks";
import { Flowbite, Navbar } from "flowbite-react";
import flowbiteTheme from "../../themes/flowbite_theme";
import "css/pages/navbar.css";

const logo = "/public/images/tc_logo_nobg.png";

const navigation = [
    { name: "Contact", href: "/contact-us" },
    { name: "Apply Now", href: "/apply-now" },
    { name: "Sign In", href: "/login" },
];

const navigation_authenticated = [
    { name: "My Account", href: "/account/#" },
    { name: "Loan Overview", href: "/account/#loan" },
    { name: "Payment Options", href: "/account/#payment" },
    { name: "My Profile", href: "/account/#profile" },
    { name: "Loan History", href: "/account/#history" },
    { name: "Contact", href: "/contact-us" },
];

export default function Header() {
    const auth = useAuth();
    const [navbar, setNavbar] = useState(false);
    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    window.addEventListener("scroll", changeBackground);

    return (
        <Flowbite theme={{ mode: "light", theme: flowbiteTheme }}>
            <div className="navbar_acc font-heebo border-b-2 border-gray-200 sm:font-inter py-4">
                <Navbar className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            className="w-[200px] h-auto"
                            alt="Today Cash Logo"
                        />
                    </Navbar.Brand>
                    <div
                        className="md:hidden w-auto"
                    >
                        &nbsp;
                    </div>
                    <Navbar.Toggle />
                    {(auth.loading || !auth.user) && (
                        <Navbar.Collapse>
                            {navigation.map((item) => (
                                 <Navbar.Link
                                     key={item.name}
                                     href={item.href}
                                     className={item.href === "/login" ? "flex justify-center items-center mt-4 md:mt-0 border-2 border-solid border-highlight-100 px-6 transition-colors duration-300 ease-in-out hover:border-highlight-100" : "flex justify-center items-center"}
                                 >
                                     {item.name}
                                 </Navbar.Link>
                             ))}
                        </Navbar.Collapse>
                    )}
                    {!auth.loading && auth.user && (
                        <Navbar.Collapse>
                            {navigation_authenticated.map((item) => (
                                <Navbar.Link
                                    key={item.name}
                                    href={item.href}
                                    className="flex justify-center items-center"
                                >
                                    {item.name}
                                </Navbar.Link>
                            ))}
                            <Navbar.Link
                                href="#"
                                className="flex justify-center items-center mt-4 md:mt-0 border-2 border-solid border-highlight-100 px-6 transition-colors duration-300 ease-in-out hover:border-highlight-100"
                                onClick={() => {
                                    auth.sign_out(() => {
                                        window.location = "/";
                                    });
                                }}
                            >
                                Sign Out
                            </Navbar.Link>
                        </Navbar.Collapse>
                    )}
                </Navbar>
            </div>
        </Flowbite>
    );
}
